import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { Link } from "gatsby"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
        query {
            fourOhfour: file(relativePath: { eq: "404.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
  `)
  return (
    <Layout style={{backgroundColor: "white"}}>
      <SEO title="404: Not found" />
      <div style={{ textAlign: "center", marginTop: "20px"}}>
        <h1>Oops!</h1>
        <p>Looks like you might be lost.</p>
        <Link className="navLink" to="/" >Take me back</Link>
      </div>     
      <Img style={{ maxWidth: "1000px", margin: "10vh auto" }} fluid={data.fourOhfour.childImageSharp.fluid} />
    </Layout>
  )
}

export default NotFoundPage
